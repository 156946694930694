import * as React from "react"
import { css, Themed } from "theme-ui"
import Header from "../gatsby-theme-blog/components/header"
import Seo from "gatsby-theme-blog/src/components/seo"

const About = ({ children, ...props }) => {  

  return (
    <Themed.root>
      <Header title='Letters to an Aspiring PM' {...props} />
      <div>
        <div
          css={css({
            maxWidth: `container`,
            mx: `auto`,
            px: 3,
            py: 4,
          })}
        >
          <p>Hey, Sean here.</p>
          <h1>I remember feeling lost when I got started in product.</h1>
          <a href='http://kaulana.com' style={{float: 'right'}}>
            <img src='http://kaulana.com/sean.jpg' alt='Sean, the author' style={{padding: 10, width: 200, height: 200, borderRadius: 99999}} />
          </a>
          <p>And I wish I'd had a shoulder to lean on. These letters are the hard-earned lessons of getting it wrong, repeatedly, that I hope to pay forward.</p>
          <p>But I don't have it all figured out. I don't know any more than you do.</p>
          <p>What I do notice is that people in our field tend to focus on methods, tools, and techniques as the hard-to-master parts of product management. Not the emotional underbelly, the fears and the doubts.</p>
          <p>The part where I felt the most unprepared.</p>
          <h2>So let's help each other out.</h2>
          <p>I want these letters to be genuinely useful to you. That only works <strong>when I get a chance to hear from you</strong> about what resonated, what fell flat and what's still missing.</p>
          <p>I want to do it on your terms. There's no email list to sign up for here, no newsletter to subscribe to. I'm not here to monetize you.</p>
          
          <p>
            <strong><Themed.a href='https://airtable.com/applDKhoZ7TGptTI0/shrtyWImT3BLoi6qo' rel='nofollow'>Write me directly from here</Themed.a>.</strong>
            {` `}
            Or if you prefer social, I'm
            {` `}
            <em>@skaulana</em> most places.
          </p>
          <p>I can't wait to hear from you.</p>
          <Themed.hr />
          <h3>A few acknowledgements 🙏</h3>
          <p>
            Much love to
            {` `}
            <Themed.a href='https://andreasconradi.works/'>Andreas</Themed.a>,{` `}
            <Themed.a href='https://prtr.co/'>Brad</Themed.a>,{` `}
            Holly,{` `}
            <Themed.a href='https://twitter.com/MeetKevon'>Kevon</Themed.a>,{` `}
            <Themed.a href='http://iamk.im/'>Kim</Themed.a>,{` `}
            <Themed.a href='https://twitter.com/thelisawei'>Lisa</Themed.a> ❤️,{` `}
            Manpreet,{` `}
            <Themed.a href='https://megantrotter.com/'>Meg</Themed.a>,{` `}
            Milan,{` `}
            <Themed.a href='https://twitter.com/_Noam'>Noam</Themed.a>,{` `}
            Oana,{` `}
            Sabz,{` `}
            <Themed.a href='https://productmuses.substack.com/'>Tiffany</Themed.a>,{` `}
            and Travis{` `}
            for their support and inspiration in bringing this project to life.
          </p>

          <br />
          © {new Date().getFullYear()}
          {` `}&bull;{` `}
          <Themed.a href="/">Home</Themed.a>
        </div>
      </div>
      <Seo title='About' />
    </Themed.root>
  )
}

export default About